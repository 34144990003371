<template>
  <div id="device" :style="{ width: '100%', height: '400px' }"></div>
</template>


<script setup>
import {  onMounted,onUnmounted, onUpdated } from "vue"
import * as echarts from 'echarts';
/* eslint-disable */
// eslint-disable-next-line vue/no-setup-props-destructure
import { defineProps } from "vue"

const props = defineProps({
  dynamicProp: {
    type: Object,
    required: true
  }
})

const echart = echarts;

onMounted(() => {
  initChart();
});

// 更新后
onUpdated(() => {
  initChart(props.dynamicProp.device);
});

onUnmounted(() => {
  echart.dispose;
});

const initChart =(data)=>{
const chart = echart.init(document.getElementById("device"));
chart.setOption({
  tooltip: {
  trigger: 'item'
},
legend: {
  top: '5%',
  left: 'center'
},
series: [
  {
    name: '收款设备收入',
    type: 'pie',
    radius: ['40%', '70%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    emphasis: {
      label: {
        show: true,
        fontSize: 40,
        fontWeight: 'bold'
      }
    },
    labelLine: {
      show: false
    },
    data,
    tooltip: {
      trigger: 'item',
      show: true,
      formatter: function(params) {
        return params.seriesName + '<br />' + params.marker + params.name + ' : ' + params.value + '元' + '<br />' + '占比 : ' + params.percent + '%' + '<br />'
         + '设备号 : ' + params.data.serial_number + '<br />' + '支付金额 : ' + params.data.fee_amount + '元' + '<br />' + '支付笔数 : ' + params.data.pay_total + '笔';
      }
    }
  }
]

})

window.onresize = function() {chart.resize()};
return { initChart };    
}
</script>